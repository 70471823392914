"use client"; // Error components must be Client Components

import { useEffect } from "react";

import { useRouter } from "next/navigation";

import { Button, Column, Row, TheFooterJP, TheHeaderJP } from "@/components";
import { sendError } from "@/utils/sentry";

import styles from "./error.module.scss";

interface ErrorProps {
  error?: Error;
  reset?: () => void;
}

export default function Error({ error, reset }: ErrorProps): React.ReactNode {
  const router = useRouter();

  useEffect(() => {
    sendError(error);
  }, [error]);

  return (
    <Column>
      <TheHeaderJP />
      <Column className={styles.container}>
        <Column className={styles.card}>
          <Column className={styles.titlesContainer}>
            <h3 className={styles.title}>申し訳ございません。</h3>
            <h3 className={styles.title}>予期せぬエラーが発生しました。</h3>
          </Column>
          <Row className={styles.actions}>
            <Button variants="outlined" rounded onClick={() => router.push("/")}>
              ホーム画面に戻る
            </Button>
            <Button variants="yellow" rounded onClick={() => reset?.()}>
              リトライ
            </Button>
          </Row>
        </Column>
      </Column>
      <TheFooterJP />
    </Column>
  );
}
